import { createAsyncThunk, createSlice, Action } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosInstance from '../../utils/axios';
import { AxiosRequestConfig } from 'axios';

interface Stats {
  exams: number | null;
  archives: number | null;
  students: number | null;
  status: string | boolean | null;
  latestResults: any[];
  latestCorrections: any[];
  stats: any[];
  statsClassExams: SelectExam[];
  correctionClassExams: SelectExam[];
  resultsClassExams: SelectExam[];
  statsClass?: string;
  correctionClass?: string;
  resultsClass?: string;
  error: string | null;
}
export interface SelectExam {
  _id: string;
  name: string;
}
interface Statistics {
  classId: unknown;
  sort?: string;
  examId: string;
}
const initialState: Stats = {
  exams: null,
  status: 'idle',
  students: null,
  archives: null,
  latestCorrections: [],
  stats: [],
  latestResults: [],
  statsClassExams: [],
  correctionClassExams: [],
  resultsClassExams: [],
  statsClass: '',
  correctionClass: '',
  resultsClass: '',
  error: null,
};
interface AxiosParams extends AxiosRequestConfig {
  sort?: string;
  current?: number;
  pageSize?: number;
  total?: number;
  perPage?: number;
  page?: number;
  limit?: number;
}
interface ExamByClassId {
  classId: string;
}
export const fetchExamByClassId = createAsyncThunk(
  'exam/fetchExamByClassId',
  async ({ classId, ...params }: ExamByClassId & AxiosParams, { getState }) => {
    try {
      const response = await axiosInstance.get(`/exams/all-by-class/${classId}`, { params });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchStatistics = createAsyncThunk(
  '/statistics/stats',
  async ({ classId, examId }: Statistics, { getState }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/teacher-dashboards/statistics/${classId}/${examId}`
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchLatestCorrections = createAsyncThunk(
  '/statistics/corrections',
  async ({ classId, examId, perPage }: any, { getState }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/teacher-dashboards/resent-correction/${classId}/${examId}`,
        {
          params: { perPage },
        }
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchLatestResults = createAsyncThunk(
  '/stats/results',
  async ({ classId, examId, ...params }: Statistics & AxiosParams, { getState }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/teacher-dashboards/resent-results/${classId}/${examId}`,
        { params }
      );
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchStats = createAsyncThunk(
  'stats',
  async (establishmentId: string, { getState }) => {
    const defaultEstablishementId = (getState() as RootState).auth?.user?.establishment[0]?._id;
    let data;
    try {
      const response = await axiosInstance.get(
        `/teacher-dashboards/getInfo/${
          establishmentId === '' ? defaultEstablishementId : establishmentId
        }`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    selectStatsExams(state, action) {
      state.statsClassExams = action?.payload;
    },
    selectCorrectionExams(state, action) {
      state.correctionClassExams = action?.payload;
    },
    selectResultsExams(state, action) {
      state.resultsClassExams = action?.payload;
    },
    selectStatsClass(state, action) {
      state.statsClass = action?.payload;
    },
    selectCorrectionClass(state, action) {
      state.correctionClass = action?.payload;
    },
    selectResultsClass(state, action) {
      state.resultsClass = action?.payload;
    },
    emptyStats(state) {
      state.latestResults = [];
      state.stats = [];
      state.latestCorrections = [];
      state.statsClassExams = [];
      state.correctionClassExams = [];
      state.resultsClassExams = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStats.pending, (state) => {
        state.status = true;
        state.error = null;
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.status = false;

        state.exams = action?.payload?.data?.totalExam;
        state.students = action?.payload?.data?.total_student;
        state.archives = action.payload.data?.total_archive;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.status = 'fail';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(fetchLatestResults.pending, (state) => {
        state.status = true;
        state.error = null;
      })
      .addCase(fetchLatestResults.fulfilled, (state, action) => {
        state.status = false;
        state.latestResults = action?.payload?.data?.payload;
      })
      .addCase(fetchLatestResults.rejected, (state, action) => {
        state.status = 'fail';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(fetchLatestCorrections.pending, (state) => {
        state.status = true;
        state.error = null;
      })
      .addCase(fetchLatestCorrections.fulfilled, (state, action) => {
        state.status = false;
        state.latestCorrections = action?.payload?.data?.payload;
      })
      .addCase(fetchLatestCorrections.rejected, (state, action) => {
        state.status = 'fail';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(fetchStatistics.pending, (state) => {
        state.status = true;
        state.error = null;
      })
      .addCase(fetchStatistics.fulfilled, (state, action) => {
        state.status = false;
        state.stats = action?.payload?.data?.payload;
      })
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.status = 'fail';
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

// Export the actions and reducer
export const {
  emptyStats,
  selectStatsExams,
  selectCorrectionExams,
  selectResultsExams,
  selectStatsClass,
  selectCorrectionClass,
  selectResultsClass,
} = statsSlice.actions;
export default statsSlice.reducer;
