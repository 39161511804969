import { combineReducers, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import notifierReducer from '../slices/notifierSlice';
import departmentReducer from '../slices/departmentSlice';
import modalReducer from '../slices/modalSlice';
import schoolYearReducer from '../slices/schoolYearSlice';
import userReducer from '../slices/UserSlice';
import classReducer from '../slices/ClassSlice';
import establishmentReducer from '../slices/EstablishmentSlice';
import examReducer from '../slices/ExamSlice';
import studentReducer from '../slices/StudentSlice';
import searchReducer from '../slices/SearchSlice';
import authReducer from '../slices/AuthSlice';
import notificationReducer from '../slices/NotificationSlice';
import statsReducer from '../slices/statsSlice';
import correctionReducer from '../slices/correctionSlice';
import exerciceSlice from '../slices/exerciceSlice';
import establishmentsReducer from '../slices/establishementsSlice';
import subjectsReducer from '../slices/subjectsSlice';
import customizedHeaderReducer from '../slices/customizedHeaderSlice';
import cardOptionsReducer from '../slices/cardOptionsSlice';
import examCreationSlice from '../slices/ExamManipSlice';
import SessionsSlice from '../slices/Sessions';
import BulletinSlice from '../slices/Bulletin';
import StudentBulletin from '../slices/StudentBulletin';
import AdminReducer from '../slices/AdminSlice';
import selectsReducer from '../slices/SelectsSlice';
import calendarReducer from '../slices/calendarSlice';
interface RootState {
  auth: ReturnType<typeof authReducer>;
  notifier: ReturnType<typeof notifierReducer>;
  department: ReturnType<typeof departmentReducer>;
  modal: ReturnType<typeof modalReducer>;
  schoolYear: ReturnType<typeof schoolYearReducer>;
  user: ReturnType<typeof userReducer>;
  classes: ReturnType<typeof classReducer>;
  establishment: ReturnType<typeof establishmentReducer>;
  exams: ReturnType<typeof examReducer>;
  students: ReturnType<typeof studentReducer>;
  search: ReturnType<typeof searchReducer>;
  notification: ReturnType<typeof notificationReducer>;
  stats: ReturnType<typeof statsReducer>;
  corrections: ReturnType<typeof correctionReducer>;
  exercises: ReturnType<typeof exerciceSlice>;
  establishements: ReturnType<typeof establishmentsReducer>;
  subjects: ReturnType<typeof subjectsReducer>;
  customizedHeader: ReturnType<typeof customizedHeaderReducer>;
  cardOptions: ReturnType<typeof cardOptionsReducer>;
  examCreation: ReturnType<typeof examCreationSlice>;
  session: ReturnType<typeof SessionsSlice>;
  bulletin: ReturnType<typeof BulletinSlice>;
  studentBulletin: ReturnType<typeof StudentBulletin>;
  admin: ReturnType<typeof AdminReducer>;
  selects: ReturnType<typeof selectsReducer>;
  calendar: ReturnType<typeof calendarReducer>;
}

const reducers: ReducersMapObject<RootState> = {
  auth: authReducer,
  notifier: notifierReducer,
  department: departmentReducer,
  modal: modalReducer,
  schoolYear: schoolYearReducer,
  user: userReducer,
  classes: classReducer,
  establishment: establishmentReducer,
  exams: examReducer,
  students: studentReducer,
  search: searchReducer,
  exercises: exerciceSlice,
  notification: notificationReducer,
  stats: statsReducer,
  corrections: correctionReducer,
  establishements: establishmentsReducer,
  subjects: subjectsReducer,
  customizedHeader: customizedHeaderReducer,
  cardOptions: cardOptionsReducer,
  examCreation: examCreationSlice,
  session: SessionsSlice,
  bulletin: BulletinSlice,
  studentBulletin: StudentBulletin,
  admin: AdminReducer,
  selects: selectsReducer,
  calendar: calendarReducer,
};

const combinedReducer: Reducer<RootState> = combineReducers(reducers);

export default combinedReducer;
