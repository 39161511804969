import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, theme } from 'antd';
import { ReactComponent as HomeIcon } from '../../assets/img/icons/home.svg';
import useMediaMatch from '@rooks/use-media-match';
import { Suspense } from 'react';
import { ReactComponent as ClassesIcon } from '../../assets/img/icons/classes.svg';
import { ReactComponent as ExamsIcon } from '../../assets/img/icons/exams.svg';
import { ReactComponent as BulletinIcon } from '../../assets/img/icons/bulletin.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/img/icons/archive.svg';
import { ReactComponent as Logo } from '../../assets/img/icons/defaultLogo.svg';
import { ReactComponent as Burger } from '../../assets/img/icons/burger.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { useLocation, useNavigate } from 'react-router';
import LoadingScreen from '../../components/ui/Loading';
import { MenuDropDown } from '../../components';
import Footer from './components/Footer';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';

const { Header, Sider, Content } = Layout;

interface SidebarProps {
  children: React.ReactNode;
}

interface MenuItem {
  key: string;
  icon: any;
  label: string;
}

const getSelectedKey = (route: string): string => {
  switch (true) {
    case route.startsWith('/exams'):
      return '/exams';
    case route.startsWith('/bulletin'):
      return '/bulletin';
    case route.startsWith('/classes'):
      return '/classes';
    case route.startsWith('/archive'):
      return '/archive';
    case route.startsWith('/me'):
      return '';
    case route.startsWith('/settings'):
      return '/settings';
    case route.startsWith('/calendar'):
      return '/calendar';
    default:
      return '/dashboard';
  }
};

const SideBar: React.FC<SidebarProps> = ({ children }) => {
  const noSidebar = useMediaMatch('(min-width:770px)');
  const [phoneSider, setPhoneSider] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const mediaMatch = useMediaMatch('(max-width: 1142px)');
  useEffect(() => {
    setCollapsed(mediaMatch);
  }, [mediaMatch]);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);
  const loading = useSelector((state: any) => state.loading);
  const { user }: any = useAuth();

  const menuItems: any[] = [
    {
      key: '/dashboard',
      icon: <HomeIcon />,
      label: 'Tableau de bord',
    },
    {
      key: '/classes',
      icon: <ClassesIcon />,
      label: 'Classes',
    },
    {
      key: '/exams',
      icon: <ExamsIcon />,
      label: 'Examens',
    },
    {
      key: '/bulletin',
      icon: <BulletinIcon />,
      label: 'Bulletin',
    },
    {
      key: '/archive',
      icon: <ArchiveIcon />,
      label: 'Archives',
    },
    {
      key: '/calendar',
      label: 'Calendrier',
      icon: <Calendar />,
    },
    user?.roles[1]?.code === 'TEACHERADMIN' || user?.roles[0]?.code === 'TEACHERADMIN'
      ? {
          key: '/settings',
          icon: <Settings />,
          label: 'Paramètres',
          children: [
            {
              key: '/settings/teachers',
              label: 'Enseignants',
            },
            {
              key: '/settings/subjects',
              label: 'Matières',
            },
            {
              key: '/settings/establishements',
              label: 'Établissements',
            },
          ],
        }
      : null,
  ];
  const { pathname } = useLocation();
  return (
    <Layout>
      {phoneSider && !noSidebar && (
        <button
          className={`phone-sider-wrraper`}
          onClick={() => {
            setPhoneSider(false);
          }}
        ></button>
      )}
      {!noSidebar && (
        <div
          className="sidebar-container"
          style={{ transform: phoneSider ? 'translateX(0)' : 'translateX(-100%)' }}
        >
          <Sider trigger={null} width={250}>
            <div className={`logo background-color-4`}>
              <Logo
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/');
                }}
              />

              <Button
                type="text"
                icon={<Burger />}
                onClick={() => setPhoneSider(false)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            </div>
            <Menu
              items={menuItems}
              onSelect={(e) => navigate(e.key)}
              selectedKeys={[currentRoute === '/' ? '/dashboard' : getSelectedKey(currentRoute)]}
            />

            <div className="content">
              <Footer collapsed={false} />
            </div>
          </Sider>
        </div>
      )}

      {noSidebar && (
        <Sider
          className={
            pathname === '/settings/teachers'
              ? 'teachers-sider'
              : pathname === '/settings/subjects'
              ? 'subjects-sider'
              : pathname === '/settings/establishements'
              ? 'establishements-sider'
              : ''
          }
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={250}
        >
          <div className={`logo background-color-${collapsed ? 2 : 4}`}>
            {!collapsed && (
              <Logo
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate('/');
                }}
              />
            )}
            <Button
              type="text"
              icon={<Burger />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </div>
          <Menu
            mode="inline"
            style={{ background: 'none' }}
            items={menuItems}
            onSelect={(e) => navigate(e.key)}
            selectedKeys={[currentRoute === '/' ? '/dashboard' : getSelectedKey(currentRoute)]}
          />
          <div className="content">
            <Footer collapsed={collapsed} />
          </div>
        </Sider>
      )}
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <div className="header">
            <div className="header-btn">
              {!noSidebar && !phoneSider && (
                <Button
                  type="text"
                  icon={<Burger />}
                  onClick={() => setPhoneSider(true)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                  }}
                />
              )}
            </div>
            <div className="header-end">
              <div className="header-user">
                <MenuDropDown name={'Hello User'} />
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideBar;
