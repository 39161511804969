import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  error: null,
};
export const getAllNotifications = createAsyncThunk<
  unknown,
  void,
  {
    rejectValue: string;
  }
>('user/fetchNotifications', async (_, thunkAPI) => {});
export const notificationSlice: any = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Export the actions and reducer
export const {} = notificationSlice.actions;
export default notificationSlice.reducer;
