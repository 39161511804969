import { createSlice } from '@reduxjs/toolkit';

export interface CustomizedHeaderState {
  showSearch: boolean;
  isOpenFilter: boolean;
  showAddForm: boolean;
  isOpenFilterClass: boolean;
  isOpenFilterCorrection: boolean;
}

const initialState: CustomizedHeaderState = {
  showSearch: false,
  isOpenFilter: false,
  showAddForm: false,
  isOpenFilterClass: false,
  isOpenFilterCorrection: false,
};

const CustomizedHeaderSlice = createSlice({
  name: 'customizedHeader',
  initialState,
  reducers: {
    setShowSearch(state, action) {
      state.showSearch = action.payload;
    },
    setShowAddForm(state, action) {
      state.showAddForm = action.payload;
      console.log(action, 'action');
    },
    setIsOpenFilter(state, action) {
      state.isOpenFilter = action.payload;
    },
    setIsOpenFilterClass(state, action) {
      state.isOpenFilterClass = action.payload;
    },
    setIsOpenFilterCorrection(state, action) {
      state.isOpenFilterCorrection = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setShowSearch,
  setIsOpenFilter,
  setShowAddForm,
  setIsOpenFilterClass,
  setIsOpenFilterCorrection,
} = CustomizedHeaderSlice.actions;
export default CustomizedHeaderSlice.reducer;
