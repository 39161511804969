import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notify } from '../../contexts/AntdNotifConfigProvider';
import axiosInstance from '../../utils/axios';

export interface LastCorrected {
  label: string;
  value: string;
}
export interface Rate {
  question: string;
  score: number;
}
interface CorrectionState {
  status: string | boolean;
  rates: Rate[];
  examTotal: number;
  exs: Rate[];
  error: string;
  remark: { content: string; title: string };
}
const initialState: CorrectionState = {
  status: 'idle',
  rates: [],
  exs: [],
  examTotal: 0,
  remark: { content: '', title: '' },
  error: 'idle',
};
interface Remark {
  title: string;
  content: string;
}
interface OneCorrectionProps {
  examId?: string;
  classId?: string;
  studentId?: string;
  score?: number;
  remark?: Remark;
}
interface CorrectionPost extends OneCorrectionProps {
  questionsCorrection?: Rate[];
}
export const fetchOneCorrection = createAsyncThunk(
  'corrections/fetchCorrection',
  async ({ examId, classId, studentId }: OneCorrectionProps, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/correction-exams/${examId}/${classId}/${studentId}`
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postCorrectionData = createAsyncThunk(
  'corrections/postCorrection',
  async (payload: CorrectionPost, thunkApi) => {
    let data;

    payload.questionsCorrection = payload.questionsCorrection?.map((el) => ({
      question: el.question,
      score: el.score,
    }));

    try {
      const response = await axiosInstance.post('/correction-exams', {
        ...payload,
      });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const examCreateCopy = createAsyncThunk(
  'corrections/postCorrection',
  async (id: any, thunkApi) => {
    let data;

    try {
      const response = await axiosInstance.post(`/exams/create-exam-copie/${id}`);
      data = await response.data;
      if (response.status === 200) {
        notify("copie d'examen créée avec succès", 'success');
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const correctionSlice = createSlice({
  name: 'correction',
  initialState,
  reducers: {
    repointQuestion(state, action) {
      const newIndex = action?.payload?.index;
      const newScore = action?.payload?.score;
      if (newIndex === undefined) state?.rates?.push(action?.payload);
      else {
        state.rates[newIndex].score = newScore;
      }
    },
    repointEx(state, action) {
      if (action?.payload?.index === undefined) state?.exs?.push(action?.payload);
      else {
        state.exs[action?.payload?.index].score = action?.payload?.score;
      }
    },
    emptyCorrection(state) {
      state.rates = [];
      state.exs = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneCorrection.pending, (state) => {})
      .addCase(fetchOneCorrection.fulfilled, (state: any, { payload }) => {
        state.remark = payload?.data?.remark || { content: '', title: '' };
        state.absent = payload?.data?.absent || false;
        state.unranked = payload?.data?.unranked || false;
        state.score = payload?.data?.score || false;
        state.rates = payload.data.questionsCorrection ? payload?.data?.questionsCorrection : [];
        state.examTotal = payload.data.rate || 20;
      })
      .addCase(fetchOneCorrection.rejected, (state, action) => {});
  },
});
export const { repointQuestion, repointEx, emptyCorrection } = correctionSlice.actions;
export default correctionSlice.reducer;
