import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {};

const selectsSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    saveSelectData(state, action) {
        const {pathname,name,...rest}=action.payload
      state[`${pathname}/${name}`] = {
        ...rest
      };
    },
  },
});

export const {saveSelectData} = selectsSlice.actions;

export default selectsSlice.reducer;
