import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { User } from '../../layouts/Sidebar/components/Footer';
import api from '../../api/axios';
import { RootState } from '../store';
import uuid from 'react-uuid';
import { AxiosProxyConfig } from 'axios';

export interface Exam {
  _id: string;
  name: string;
  students: string;
  establishment: string[];
  classes: any[];
  progress?: number;
  status: boolean;
  createdAt: Date;
  coverage: number;
  updatedAt: Date;
}
export interface EditExamData {
  name: string;
  examId: string;
}
type PaginationExams = {
  page?: number;
  pageSize?: number;
  search?: string;
  perPage?: number;
  sort?: string;
  _id?: any;
};
interface ExamState {
  exams: Exam[];
  archivedExam: Exam[];
  examsList: any[];
  oneExam: any;
  fetchedExam: any;
  isLoading: boolean;
  error: string | null;
  establishmentId: string;
  shouldFetchExams: boolean;
  students: object;
  searchValue: string;
  paginationExam: {
    current: number;
    pageSize: number;
    totalPages: number;
    total: number;
    perPage: number;
  };
  paginationExamArchive: {
    current: number;
    pageSize: number;
    total: number;
    perPage: number;
    totalPages: number;
  };
  classFilter: any[];
  correctionCoverage: number[];
  sessions: any[];
}

const initialState: ExamState = {
  exams: [],
  archivedExam: [],
  examsList: [],
  oneExam: {},
  fetchedExam: {},
  isLoading: false,
  error: null,
  establishmentId: '',
  shouldFetchExams: false,
  students: {},
  searchValue: '',
  paginationExam: {
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  paginationExamArchive: {
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  classFilter: [{}],
  correctionCoverage: [0, 100],
  sessions: [],
};

export const fetchExam = createAsyncThunk('exam/fetchExam', async (props: any, { getState }) => {
  const { pageSize, ...params } = props;
  const establishmentId = localStorage.getItem('activeEstablishmentId');

  try {
    const response = await axiosInstance.get(`/exams/${establishmentId}`, {
      params,
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchExamArchive = createAsyncThunk(
  'exam/fetchExamArchive',
  async (props: any, { getState }) => {
    const { pageSize, ...params } = props;
    const establishmentId = localStorage.getItem('activeEstablishmentId');

    try {
      const response = await axiosInstance.get(`/exams/${establishmentId}?deleted=true`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchSessions = createAsyncThunk('session/all', async (props: any, thunkApi) => {
  try {
    const res = await axiosInstance.get(`/sessions`);

    return res.data.data;
  } catch (error: any) {
    thunkApi.rejectWithValue('there was eror');
  }
});
export const addExam = createAsyncThunk('exam/create', async (payload: object, thunkApi) => {
  let data;
  try {
    const response = await axiosInstance.post(`/exams`, {
      ...payload,
    });
    data = await response.data.data;
    if (response.status === 200) {
      return { status: 'success', data };
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    );
  }
});
export const setCase = createAsyncThunk('exam/case', async (payload: object, thunkApi) => {
  let data;
  try {
    const response = await axiosInstance.post(`/correction-exams/student-as-absent`, {
      ...payload,
    });
    data = await response.data.data;
    if (response.status === 200) {
      return { status: 'success', data };
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    );
  }
});
export const directNote = createAsyncThunk('exam/case', async (payload: object, thunkApi) => {
  let data;
  try {
    const response = await axiosInstance.post(`/correction-exams/grade-student`, {
      ...payload,
    });
    data = await response.data.data;
    if (response.status === 200) {
      return { status: 'success', data };
    }
  } catch (err) {
    return Promise.reject(
      err?.response && err?.response?.data
        ? err?.response?.data
        : err.message
        ? err.message
        : data?.message
    );
  }
});

export const getOneExam = createAsyncThunk('exam/getOne', async (id: string, thunkApi) => {
  let data;

  try {
    const response = await axiosInstance.get(`/exams/one/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data.data.exam.exam;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const editExam = createAsyncThunk('exam/edit', async (props: EditExamData, thunkApi) => {
  try {
    const { examId, ...data } = props;
    const res = await axiosInstance.patch(`/exams/${examId}`, data);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
});
export const deleteExam = createAsyncThunk('exams/deleteExam', async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/exams/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
});
export const archiveExam = createAsyncThunk('exams/archiveExam', async (id: string) => {
  try {
    const response = await axiosInstance.patch(`/exams/archive/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});
export const unarchiveExam = createAsyncThunk('exams/unarchiveExam', async (id: string) => {
  try {
    const response = await axiosInstance.put(`/exams/unarchive/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});
export const fetchExamList = createAsyncThunk(
  'exam/fetchExamList',
  async (props: any, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');
    const { classId } = props;
    try {
      const response = await axiosInstance.get(`/exams/${establishmentId}?classId=${classId}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    emptyExam(state) {
      state.oneExam = '';
    },
    setExamSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setPageExam(state, action) {
      state.paginationExam.current = action.payload;
    },
    setPageSize(state, action) {
      state.paginationExam.pageSize = action.payload;
      state.paginationExam.perPage = action.payload;
    },
    setPageExamArchive(state, action) {
      state.paginationExamArchive.current = action.payload;
    },
    setOneExam(state, action) {
      state.oneExam = action.payload;
    },
    setClassFilter(state, action) {
      state.classFilter.push(action.payload);
    },
    setDeleteClassFilter(state, action) {
      const newArray = state.classFilter.filter((obj) => obj._id !== action.payload);
      state.classFilter = newArray;
    },
    setCorrectionCoverageFilter(state, action) {
      state.correctionCoverage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExam.fulfilled, (state, action) => {
        state.exams = action.payload.payload;
        state.isLoading = false;
        state.error = null;
        state.paginationExam.current = action.payload.meta.page;
        state.paginationExam.total = action.payload.meta.totalDocs;
        state.paginationExam.totalPages = action.payload.meta.totalPages;
        state.shouldFetchExams = false;
      })
      .addCase(fetchExam.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.sessions = action.payload.payload.docs;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(fetchSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExamArchive.fulfilled, (state, action) => {
        state.archivedExam = action.payload.payload;
        state.isLoading = false;
        state.error = null;
        state.paginationExamArchive.current = action.payload.meta.page;
        state.paginationExamArchive.total = action.payload.meta.totalDocs;
        state.paginationExamArchive.totalPages = action.payload.meta.totalPages;
        state.shouldFetchExams = false;
      })
      .addCase(fetchExamArchive.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(deleteExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(archiveExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(unarchiveExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unarchiveExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(addExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(editExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(getOneExam.fulfilled, (state, action) => {
        state.isLoading = false;
        state.oneExam = action.payload;
        state.fetchedExam = action.payload;
        state.error = null;
        state.shouldFetchExams = true;
      })
      .addCase(getOneExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneExam.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch exam';
      })
      .addCase(fetchExamList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExamList.fulfilled, (state, action) => {
        state.examsList = action.payload;
        state.isLoading = false;
        state.error = null;
        state.shouldFetchExams = false;
      })
      .addCase(fetchExamList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch exam';
      });
  },
});

export const {
  setExamSearchValue,
  setPageExam,
  setPageSize,

  setPageExamArchive,

  setClassFilter,
  setDeleteClassFilter,
  setCorrectionCoverageFilter,
  emptyExam,
} = examSlice.actions;
export default examSlice.reducer;
