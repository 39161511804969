import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../utils/axios';
import { RootState } from '../store';

interface StudentsBulletin {
  studentBulletin: any;
  error: string;
}

const initialState: StudentsBulletin = {
  studentBulletin: {
    sessions: [],
    exams: [],
    studentInfo: [],
  },
  error: '',
};

export const fetchStudentBulletin = createAsyncThunk(
  'fetchStudentBulletin/studentBulletin',
  async (props: any, { getState }) => {
    const { classId, studentId, subjectId, ...params } = props;

    try {
      const response = await axiosInstance.get(
        `/mark-sheets/student/${studentId}/class/${classId}/subject/${subjectId}`,
        {
          params,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data.message || 'Something went wrong.';
    }
  }
);

export const StudentBulletin = createSlice({
  name: 'studentBulletin',
  initialState,
  reducers: {
    removeBullletin(state){
      state.studentBulletin= {
        sessions: [],
        exams: [],
        studentInfo: [],
      }
  }
},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentBulletin.pending, (state) => {
        state.error = '';
      })
      .addCase(fetchStudentBulletin.fulfilled, (state, { payload }) => {
        state.studentBulletin = payload;
      })
      .addCase(fetchStudentBulletin.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export const {removeBullletin} = StudentBulletin.actions;
export default StudentBulletin.reducer;
