import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../utils/axios';

interface Sessions {
  sessions: {
    docs: {
      _id: string;
      name: string;
      private: boolean;
      status: boolean;
      createdAt: string;
      updatedAt: string;
    }[];
  };
  error: string;
}

const initialState: Sessions = {
  sessions: { docs: [] },
  error: '',
};

export const fetchSessions = createAsyncThunk('exam/Sessions', async (props: any, { getState }) => {
  const { headers } = props;
  try {
    let res;

    if (headers) {
      res = await axiosInstance.get(`/sessions`, {
        headers: { Authorization: `Bearer ${headers}` },
      });
    } else {
      res = await axiosInstance.get('/sessions');
    }
    return res.data.data;
  } catch (error) {
    throw error;
  }
});

export const SessionsSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.pending, (state) => {
        state.error = '';
      })
      .addCase(fetchSessions.fulfilled, (state, { payload }) => {
        state.sessions = payload.payload;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.sessions.docs = [];
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

// Export the actions and reducer
export const {} = SessionsSlice.actions;
export default SessionsSlice.reducer;
