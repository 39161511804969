import { createSlice } from '@reduxjs/toolkit';

export interface CardOptionsState {
  isOpenPopUp: boolean;
  isOpenPopUpId: string;
  assignedName: string;
  assignedClasses: any[];
  showEditPopup: boolean;
  showDeletePopup: boolean;
  showArchivePopup: boolean;
  showCopyClassPopUp: boolean;
}

const initialState: CardOptionsState = {
  isOpenPopUp: false,
  isOpenPopUpId: '',
  assignedName: '',
  assignedClasses: [],
  showEditPopup: false,
  showDeletePopup: false,
  showArchivePopup: false,
  showCopyClassPopUp: false,
};

const CardOptionsSlice = createSlice({
  name: 'customizedHeader',
  initialState,
  reducers: {
    setIsOpenPopUp(state, action) {
      state.isOpenPopUp = action.payload;
    },
    setIsOpenPopUpId(state, action) {
      state.isOpenPopUpId = action.payload;
    },
    setAssignedName(state, action) {
      state.assignedClasses = action.payload.assignedClasses;
      state.assignedName = action.payload.assignedName;
    },
    setShowEditPopup(state, action) {
      state.showEditPopup = action.payload;
    },
    setShowDeletePopup(state, action) {
      state.showDeletePopup = action.payload;
    },
    setShowArchivePopup(state, action) {
      state.showArchivePopup = action.payload;
    },
    setCopyClassPopUp(state, action) {
      state.showCopyClassPopUp = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setIsOpenPopUp,
  setIsOpenPopUpId,
  setAssignedName,
  setShowEditPopup,
  setShowDeletePopup,
  setCopyClassPopUp,
  setShowArchivePopup,
} = CardOptionsSlice.actions;
export default CardOptionsSlice.reducer;
