import React from 'react';
import { Navigate } from 'react-router';
import useAuth from '../../hooks/useAuth';

const AuthGuard = ({ children }: { children: React.ReactElement }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;

};

export default AuthGuard;
