import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import Exercise from './exercice';

import axiosInstance from '../../utils/axios';
import { useAppDispatch } from '../../hooks/redux';
import { getOneExam } from './ExamSlice';

interface ExercisesState {
  exercises: Exercise[];
  totalPoints: 0;
  bigQuestions: [];
  mediumQuestions: [];
  smallQuestions: [];
  isLoading: boolean;
}

const initialState: ExercisesState = {
  exercises: [],
  totalPoints: 0,
  bigQuestions: [],
  mediumQuestions: [],
  smallQuestions: [],
  isLoading: true,
};

export const createExercise = createAsyncThunk(
  'exercise/create',
  async ({ id, payload }: any, thunkApi) => {
    let data;

    try {
      const response = await axiosInstance.post(`exercises/${id}`, payload);
      data = await response.data.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const removeExercise = createAsyncThunk('exercise/remove', async (props: any, thunkApi) => {
  try {
    const { id, examId } = props;
    const response = await axiosInstance.delete(`/exercise/deleteExercise/${id}`);

    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
});
export const updateExercise = createAsyncThunk('exercise/edit', async (props: any, thunkApi) => {
  try {
    const { id, body } = props;
    const response = await axiosInstance.patch(`/exercise/edit/${id}`, body);
    return response.data.data;
  } catch (error) {
    throw error.response.data;
  }
});
export const createBigQuestion = createAsyncThunk(
  'exercise/createdBigQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { id, examId, body } = props;

      const response = await axiosInstance.post(`/exercise/${id}/createBigQuestion`, body);

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const createMediumQuestion = createAsyncThunk(
  'exercise/mediumQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { exerciseId, examId, body } = props;

      const response = await axiosInstance.post(
        `/exercise/${exerciseId}/createMediumQuestion`,
        body
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const createSmallQuestion = createAsyncThunk(
  'exercise/smallQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { exerciseId, examId, body } = props;

      const response = await axiosInstance.post(
        `/exercise/${exerciseId}/createSmallQuestion`,
        body
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const deleteBigQuestion = createAsyncThunk(
  'exercise/deleteBigQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { exerciseId, examId, body } = props;

      const response = await axiosInstance.delete(
        `/exercise/${exerciseId}/deleteBigQuestion/${body.big_id}`
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const deleteMediumQuestion = createAsyncThunk(
  'exercise/deleteMediumQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { exerciseId, examId, body } = props;
      const response = await axiosInstance.delete(
        `/exercise/${exerciseId}/deleteMediumQuestion/${body.big_id}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const deleteSmallQuestion = createAsyncThunk(
  'exercise/deleteSmallQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { exerciseId, examId, body } = props;

      const { id } = body;
      const response = await axiosInstance.delete(
        `/exercise/${exerciseId}/deleteSmallQuestion/${body.parentQuestionId}/${id}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);
const updateQuestion = createAsyncThunk(
  'exercise/updateQuestion',
  async (props: any, thunkApi: any) => {
    try {
      const { id, body } = props;
      const response = await axiosInstance.delete(`/exercise/${id}/createSmallQuestion`, body);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);
const exercisesSlice = createSlice({
  name: 'exercises',
  initialState,
  reducers: {
    addExercise: (state, action) => {
      state.exercises.push(action.payload);
    },
    deleteExercise: (state, action: PayloadAction<number>) => {
      const index = state.exercises.findIndex(
        (exercise) => exercise.questionNumber === action.payload
      );
      if (index !== -1) {
        state.exercises.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the "pending" state of the fetchUsers action
      .addCase(createExercise.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(createExercise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPoints = payload.createdExercise.totalPoints;
        state.bigQuestions = payload.createdExercise.bigQuestions;
        state.mediumQuestions = payload.createdExercise.mediumQuestions;
        state.smallQuestions = payload.createdExercise.smallQuestions;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(createExercise.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(removeExercise.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(removeExercise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(removeExercise.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(createBigQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(createBigQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(createBigQuestion.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createMediumQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(createMediumQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(createMediumQuestion.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createSmallQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(createSmallQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(createSmallQuestion.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteBigQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(deleteBigQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(deleteBigQuestion.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteMediumQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(deleteMediumQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(deleteMediumQuestion.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteSmallQuestion.pending, (state) => {
        state.isLoading = true;
      })
      // Handle the "fulfilled" state of the fetchUsers action
      .addCase(deleteSmallQuestion.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      // Handle the "rejected" state of the fetchUsers action
      .addCase(deleteSmallQuestion.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { addExercise, deleteExercise } = exercisesSlice.actions;

export default exercisesSlice.reducer;
