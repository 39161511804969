import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import uuid from 'react-uuid';

export interface Exam {
  _id: string;
  name: string;
  students: string;
  progress?: number;
  establishment: string[];
  classes: any[];
  status: boolean;
  createdAt: Date;
  coverage: number;
}
export interface EditExamData {
  name: string;
  examId: string;
}
type PaginationExams = {
  page: number;
  pageSize: number;
  search: string;
  perPage: number;
};
interface ExamState {
  exams: Exam[];

  oneExam: any;
  fetchedExam: any;
  isLoading: boolean;
  error: string | null;
}

const initialState: ExamState = {
  exams: [],

  oneExam: {},
  fetchedExam: {},
  isLoading: false,
  error: null,
};

export const getOneExam = createAsyncThunk('exam/getOne', async ({ id, type }: any, thunkApi) => {
  let data;

  try {
    const response = await axiosInstance.get(`/exams/one/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data.data.exam.exam;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const updateExam = createAsyncThunk(
  'boxes/updateExam',
  async ({ id, payload }: any, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.patch(`/exercises/update-changing/${id}`, {
        ...payload,
      });
      data = await response.data;
      if (response.status === 200) {
        return { status: 'success', data };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err?.response && err?.response?.data
          ? err?.response?.data
          : err.message
          ? err.message
          : data?.message
      );
    }
  }
);

const examCreationSlice = createSlice({
  name: 'examCreation',
  initialState,
  reducers: {
    setExamFromLocal(state, action) {
      state.oneExam = action.payload.exam;
    },
    transferParentIndexes(state, action) {
      state.oneExam.exercises[action.payload.index].bigQuestions[action.payload._id]._id =
        action.payload.new_id;
    },
    transferMediumIndexes(state, action) {
      state.oneExam.exercises[action.payload.index].mediumQuestions[
        action.payload._id
      ].parentQuestionId = action.payload.parentQuestionId;
    },
    addExercice(state, action) {
      if (state.oneExam.exercises) state.oneExam.exercises.push(action.payload);
      else state.oneExam.exercises = [action.payload];
    },
    addBigQuestion(state, action) {
      if (!state.oneExam.exercises[action.payload.index].bigQuestions) {
        state.oneExam.exercises[action.payload.index].bigQuestions = [];
      }
      state.oneExam.exercises[action.payload.index].bigQuestions.push({
        _id: uuid(),
        point: 0,
        ...action.payload.question,
      });
    },
    deleteBigQuestion(state, action) {
      state.oneExam.exercises[action.payload.exIndex].bigQuestions = action.payload.newArray;

      if (action.payload.mediumQuestionsIndex !== undefined) {
        state.oneExam.exercises[action.payload.exIndex].mediumQuestions.splice(
          action.payload.mediumQuestionsIndex,
          1
        );
        if (action.payload.smallQuestionsIndex.length > 0) {
          action.payload.smallQuestionsIndex.map((i: number) => {
            state.oneExam.exercises[action.payload.exIndex].smallQuestions.splice(i, 1);
          });
        }
      }
    },
    addMediumQuestion(state, action) {
      if (action.payload.parentQuestionId) {
        state.oneExam.exercises[action.payload.exIndex].mediumQuestions.push({
          questions: [{ point: 0, _id: uuid() }],
          parentQuestionId: action.payload.parentQuestionId,
        });
      } else {
        state.oneExam.exercises[action.payload.exIndex].mediumQuestions[
          action.payload.mediumQuestionsIndex
        ].questions?.push({ point: 0, _id: uuid() });
      }
    },
    deleteMediumQuestion(state, action) {
      if (action.payload.smallQuestionsIndexx !== undefined) {
        state.oneExam.exercises[action.payload.exIndex].smallQuestions.splice(
          action.payload.smallQuestionsIndexx,
          1
        );
      }
      if (action.payload.newArray)
        state.oneExam.exercises[action.payload.exIndex].mediumQuestions[action.payload.mediumI] =
          action.payload.newArray;
      else
        state.oneExam.exercises[action.payload.exIndex].mediumQuestions.splice(
          action.payload.mediumI,
          1
        );
    },
    addSmallQuestion(state, action) {
      if (action.payload.parentQuestionId) {
        state.oneExam.exercises[action.payload.exIndex].smallQuestions.push({
          questions: [{ point: 0 }],
          parentQuestionId: action.payload.parentQuestionId,
        });
      } else {
        state.oneExam.exercises[action.payload.exIndex].smallQuestions[
          action.payload.smallQuestionsIndex
        ].questions.push({ point: 0 });
      }
    },
    deleteSmallQuestion(state, action) {
      if (action.payload.newArray)
        state.oneExam.exercises[action.payload.exIndex].smallQuestions[action.payload.smallI] =
          action.payload.newArray;
      else
        state.oneExam.exercises[action.payload.exIndex].smallQuestions.splice(
          action.payload.smallI,
          1
        );
    },
    renameExercise(state, action) {
      state.oneExam.exercises[action.payload.index].title = action.payload.name;
    },
    repointExercise(state, action) {
      state.oneExam.exercises[action?.payload?.index].point = action?.payload?.point;
    },
    repointBigQuestion(state, action) {
      state.oneExam.exercises[action.payload.exIndex].bigQuestions[action.payload.index].point =
      action.payload.point;
    },
    repointMediumQuestion(state, action) {
      state.oneExam.exercises[action.payload.exIndex].mediumQuestions[
        action.payload.index
      ].questions[action.payload.num].point = action.payload.point;
    },
    repointSmallQuestion(state, action) {
      state.oneExam.exercises[action.payload.exIndex].smallQuestions[
        action.payload.index
      ].questions[action.payload.num].point = action.payload.point;
    },
    removeExercise(state, action) {
      state.oneExam.exercises = action.payload.newArray;
    },
    editBigQuestion(state, action) {
      if (state?.oneExam?.exercises[action?.payload?.exIndex]?.bigQuestions?.length > 0)
        state.oneExam.exercises[action.payload.exIndex].bigQuestions[
          action?.payload?.index
        ].content = action?.payload?.content;
    },
    editMediumQuestion(state, action) {
      if (state?.oneExam?.exercises[action?.payload?.exIndex]?.mediumQuestions?.length > 0)
        state.oneExam.exercises[action?.payload?.exIndex].mediumQuestions[
          action?.payload?.index
        ].questions[action?.payload?.num].content = action?.payload?.content;
    },
    editSmallQuestion(state, action) {
      if (
        state?.oneExam?.exercises &&
        state?.oneExam?.exercises[action?.payload?.exIndex]?.smallQuestions?.length > 0
      ) {
        state.oneExam.exercises[action?.payload?.exIndex].smallQuestions[
          action.payload.index
        ].questions[action.payload.num].content = action?.payload?.content;
      }
    },
    fetchedExamCreation(state, action) {
      state.fetchedExam = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneExam.fulfilled, (state, action) => {
        if (action.meta.arg.type) {
          state.fetchedExam = action.payload;
          state.isLoading = false;
        } else {
          state.isLoading = false;
          state.oneExam = action.payload;
          state.fetchedExam = action.payload;
          state.error = null;
        }
      })
      .addCase(getOneExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneExam.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch exam';
      });
  },
});

export const {
  transferMediumIndexes,
  transferParentIndexes,
  deleteBigQuestion,
  deleteSmallQuestion,
  renameExercise,
  deleteMediumQuestion,
  addMediumQuestion,
  addBigQuestion,
  addSmallQuestion,
  repointBigQuestion,
  repointSmallQuestion,
  repointMediumQuestion,
  repointExercise,
  editMediumQuestion,
  addExercice,
  editSmallQuestion,
  fetchedExamCreation,
  editBigQuestion,
  setExamFromLocal,
  removeExercise,
} = examCreationSlice.actions;
export default examCreationSlice.reducer;
