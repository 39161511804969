import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../utils/axios';

const initialState: any = {
  establishements: {
    payload: [],
    meta: {},
    error: false,
    loading: false,
  },
  subjects: {
    payload: [],
    meta: {},
    error: false,
    loading: false,
  },
  teachers: {
    payload: [],
    meta: {},
    error: false,
    loading: false,
  },
  teacher: {},

  photos: {
    payload: [],
    meta: {},
    error: false,
    loading: false,
  },
};
///photos

export const fetchSubjectsPhotos = createAsyncThunk(
  'admin/fetchSubjectsPhotos',
  async (params: any, query: any) => {
    let data;

    try {
      const response = await axiosInstance.get(`/photos`, { params });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const postSubjectsPhotos = createAsyncThunk(
  'admin/postSubjectsPhotos',
  async ({ formData }: any, query: any) => {
    let data;

    try {
      const response = await axiosInstance.post(`/photos`, formData);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteSubjectsPhotos = createAsyncThunk(
  'admin/deleteSubjectsPhotos',
  async (id: any, query: any) => {
    let data;

    try {
      const response = await axiosInstance.delete(`/photos/${id}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
///subjects
export const fetchAdminSubjects = createAsyncThunk(
  'admin/fetchAdminSubjects',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/subjects`, { params });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addAdminSubjects = createAsyncThunk(
  'admin/addAdminSubjects',
  async (body: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.post(`/subjects`, { ...body });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const removeSubject = createAsyncThunk(
  'admin/removesubjectt',
  async (id: string, query: any) => {
    let data;
    try {
      const response = await axiosInstance.delete(`/subjects/${id}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateSubjects = createAsyncThunk(
  'admin/updateSubjects',
  async (values: any, query: any) => {
    const { id, ...others } = values;
    let data;
    try {
      const response = await axiosInstance.patch(`/subjects/${id}`, { ...others });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

///Establishements
export const fetchAdminEstablishements = createAsyncThunk(
  'admin/getAllEstablishments',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/establishments`, {
        params: { ...params, sort: '-createdAt' },
      });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const addEstablishement = createAsyncThunk(
  'admin/getAllEstablishments',
  async (body: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.post(`/establishments`, { ...body });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const removeEstablishement = createAsyncThunk(
  'admin/removeEstablishement',
  async (id: string, query: any) => {
    let data;
    try {
      const response = await axiosInstance.delete(`/establishments/${id}`);
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateEstablishement = createAsyncThunk(
  'admin/updateEstablishement',
  async (values: any, query: any) => {
    const { id, ...others } = values;
    let data;
    try {
      const response = await axiosInstance.patch(`/establishments/${id}`, { ...others });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
///teachers
export const fetchAllTeachers = createAsyncThunk(
  'admin/fetchAllTeachers',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/users/all-teacher`, { params });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchAdminTeachers = createAsyncThunk(
  'admin/fetchAdminTeachers',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/users/all-teacherAdmin`, { params });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const addAdmin = createAsyncThunk('admin/addAdmin', async (body: any, query: any) => {
  let data;
  try {
    const response = await axiosInstance.post(`users/add-adminTeacher `, { ...body });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(
      err.response
        ? err.response.data
          ? err.response.data.message
          : err.response.message
        : err.message
        ? err.message
        : data?.message
    );
  }
});

export const getAdmin = createAsyncThunk('admin/getAdmin', async (id: string, query: any) => {
  let data;
  try {
    const response = await axiosInstance.get(`users/${id}`);
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    removeAdmin(state, action) {
      state.teacher.payload = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminEstablishements.fulfilled, (state, action) => {
        state.establishements.payload = action.payload.data.docs;
        state.establishements.meta = action.payload.data.meta;
        state.establishements.loading = false;
      })
      .addCase(fetchAdminEstablishements.rejected, (state, action) => {
        state.establishements.error = true;
        state.establishements.loading = false;
      })
      .addCase(fetchAdminEstablishements.pending, (state, action) => {
        state.establishements.loading = true;
      })
      .addCase(fetchAdminSubjects.fulfilled, (state, action) => {
        state.subjects.payload = action.payload.data.payload;
        state.subjects.meta = action.payload.data.meta;
        state.subjects.loading = false;
      })
      .addCase(fetchAdminSubjects.rejected, (state, action) => {
        state.subjects.error = true;
        state.subjects.loading = false;
      })
      .addCase(fetchAdminSubjects.pending, (state, action) => {
        state.subjects.loading = true;
      })
      .addCase(fetchSubjectsPhotos.fulfilled, (state, action) => {
        state.photos.payload = action.payload.data.payload;
        state.photos.meta = action.payload.data.meta;
        state.photos.loading = false;
      })
      .addCase(fetchSubjectsPhotos.rejected, (state, action) => {
        state.photos.error = true;
        state.photos.loading = false;
      })
      .addCase(fetchSubjectsPhotos.pending, (state, action) => {
        state.photos.loading = true;
      })
      .addCase(postSubjectsPhotos.pending, (state, action) => {
        state.photos.loading = true;
      })
      .addCase(deleteSubjectsPhotos.pending, (state, action) => {
        state.photos.loading = true;
      })
      .addCase(fetchAdminTeachers.fulfilled, (state, action) => {
        state.teachers.payload = action.payload.data.docs;
        state.teachers.meta = action.payload.data.meta;
        state.teachers.loading = false;
      })
      .addCase(fetchAdminTeachers.rejected, (state, action) => {
        state.teachers.error = true;
        state.teachers.loading = false;
      })
      .addCase(fetchAdminTeachers.pending, (state, action) => {
        state.teachers.loading = true;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.teacher.payload = action.payload.data.user;
        state.teacher.loading = false;
      })
      .addCase(getAdmin.rejected, (state, action) => {
        state.teacher.error = true;
        state.teacher.loading = false;
      })
      .addCase(getAdmin.pending, (state, action) => {
        state.teacher.loading = true;
      });
  },
});

export const {removeAdmin} = adminSlice.actions;

export default adminSlice.reducer;
