import { createSlice } from '@reduxjs/toolkit';

interface EstablishmentState {
  activeEstablishmentId: string;
  name: string;
}

const initialState: EstablishmentState = {
  activeEstablishmentId: '',
  name: '',
};

const establishmentSlice = createSlice({
  name: 'establishment',
  initialState,
  reducers: {
    setActiveEstablishmentId(state, action: any) {

      state.activeEstablishmentId = action?.payload;
      localStorage.setItem('activeEstablishmentId', action?.payload);
    },
    setActiveEstablishmentName(state, action: any) {
      state.name = action?.payload;
      localStorage.setItem('activeEstablishmentId', action?.payload);
    },
  },
});

export const { setActiveEstablishmentId, setActiveEstablishmentName } = establishmentSlice.actions;

export default establishmentSlice.reducer;
