import React, { useEffect, useMemo } from 'react';
import EstablishmentIcon from '../../../assets/img/icons/establishment.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../data/store';
import { Divider, Radio, Space } from 'antd';
import BookLoader from '../../../components/bookLoader/BookLoader';
import { setActiveEstablishmentId } from '../../../data/slices/EstablishmentSlice';
export interface RadioOption {
  _id: string;
  name: string;
  status: boolean;
}
export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  government: string;
  establishment: RadioOption[];
  subject: string;
  password: string;
  role: [];
  _id: string;
}
function containsArabicCharacters(text: string) {
  const arabicPattern = /[\u0600-\u06FF]/;
  return arabicPattern.test(text);
}

const Footer = ({ collapsed }: any) => {
  const dispatch = useDispatch();
  const radioOptions = useSelector((state: RootState) => state?.auth?.user?.establishment);
  const establishmentId = useSelector(
    (state: RootState) => state?.auth?.user?.establishment[0]?._id
  );

  const activeEstablishmentId = useSelector(
    (state: RootState) => state?.establishment?.activeEstablishmentId
  );

  useEffect(() => {
    if (!localStorage.hasOwnProperty('activeEstablishmentId') && establishmentId !== undefined) {
      if (establishmentId === activeEstablishmentId) {
        dispatch(setActiveEstablishmentId(establishmentId));
      } else {
        localStorage.removeItem('activeEstablishmentId');
        localStorage.setItem('activeEstablishmentId', establishmentId);
        dispatch(setActiveEstablishmentId(establishmentId));
      }
    }
  }, [establishmentId]);
  const onChange = (e: any) => {
    dispatch(setActiveEstablishmentId(e.target.value));
    window.location.reload();
  };
  const initialActiveEstablishmentId = localStorage.getItem('activeEstablishmentId');
  if (!establishmentId || !radioOptions) return <BookLoader />;

  return (
    <>
      <Divider
        style={{
          background: '#d0d5dd',
        }}
      />
      <div className="footer">
        <div className="footer-establishment-title">
          <img src={EstablishmentIcon} alt="" />
          {!collapsed && <h1>Établissement</h1>}
        </div>
        <Radio.Group
          onChange={onChange}
          defaultValue={
            initialActiveEstablishmentId
              ? initialActiveEstablishmentId
              : activeEstablishmentId
              ? activeEstablishmentId
              : radioOptions[0]._id
          }
          value={
            initialActiveEstablishmentId
              ? initialActiveEstablishmentId
              : activeEstablishmentId
              ? activeEstablishmentId
              : radioOptions[0]._id
          }
        >
          <Space className={`${collapsed ? 'collapsed' : ''}`} direction="vertical">
            {radioOptions?.map((option: any, index: number) => (
              <Radio
                className={`${collapsed ? 'antd-option-column' : ''}`}
                key={option?._id}
                value={option?._id}
                name={option?.name}
              >
                {!collapsed
                  ? option?.name
                  : option?.name
                      .split(' ')
                      .slice(0, 3)
                      .map((el: string) => el[0])
                      .join(containsArabicCharacters(option?.name) ? '.' : '')}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};

export default Footer;
