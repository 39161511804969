import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import uuid from 'react-uuid';
import { message } from 'antd';
//post event
export const postEvent = createAsyncThunk('calendar/post', async (body: any, { getState }) => {
  try {
    delete body.id;

    const res = await axiosInstance.post(`/calendar`, body);
    message.success('événement créé avec succès');
    return res;
  } catch (error) {
    return Promise.reject(error.response.data.message || 'Something went wrong.');
  }
});
export const patchEvent = createAsyncThunk(
  'calendar/patch',
  async ({ id, body }: any, { getState }) => {
    try {
      if (body.id) delete body.id;

      const res = await axiosInstance.patch(`/calendar/${id}`, body);

      return res;
    } catch (error) {
      return Promise.reject(error.response.data.message || 'Something went wrong.');
    }
  }
);
//delete event
export const deleteEvents = createAsyncThunk(
  'calendar/deleteEvent',
  async (id: any, { getState }) => {
    let response;
    try {
      response = await axiosInstance.delete(`/calendar/${id}`);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
//get events
export const getEvents = createAsyncThunk(
  'calendar/getEvents',
  async (params: any, { getState }) => {
    let response;
    const establishmentId = localStorage.getItem('activeEstablishmentId');

    try {
      response = await axiosInstance.get(`/calendar/${establishmentId}`, {
        params: { ...params, perPage: 5000, page: 1 },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
//get all classes
export const fetchClassList = createAsyncThunk(
  'calendar/fetchClassList',
  async (params: any, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');
    let response;
    try {
      response = await axiosInstance.get(`/classes/${establishmentId}`, { params });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubjectsByClasses = createAsyncThunk(
  'subjects/getCommonSubjects',
  async (params: any, query: any) => {
    let data;

    try {
      const response = await axiosInstance.post(`subjects/shared-subject `, { ...params });
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const initialState: any = {
  events: [],
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    createEvent(state, action) {
      state.events.push({ ...action?.payload?.newEvent, id: uuid(), fetched: false });
    },
    emptyEvents(state) {
      state.events = [];
    },
    updateEvent(state, action) {
      const eventIndex = state.events.find(
        (event: any) => event.id === action.payload.selectedEventId
      );

      state.events.push({ ...action?.payload?.newEvent, id: eventIndex.id });
      if (eventIndex === undefined) {
        state.events.splice(eventIndex, 1);
      }
    },
    deleteEvent(state, action) {
      const eventIndex = state.events.findIndex((event: any) => event.id === action.payload);
      if (eventIndex !== -1) {
        state.events.splice(eventIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEvent.rejected, (state, action) => {
        state.events.pop();
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events = action.payload.payload.map((event: any) => {
          event.id = event._id;
          delete event._id;
          return event;
        });
      })

      .addCase(getEvents.rejected, (state, action) => {
        message.error('il y a un problème pour obtenir les événements');
      });
  },
});

export const { createEvent, updateEvent, deleteEvent, emptyEvents } = calendarSlice.actions;

export default calendarSlice.reducer;
