import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/axios';
import axiosInstance from '../../utils/axios';
import { RootState } from '../store';

export interface SubjectState {
  _id: string;
  name: string;
  status: boolean;
}
interface State {
  payload: SubjectState[];
}
const initialState: State = {
  payload: [],
};
export const fetchSubjects = createAsyncThunk(
  'register/getAllSubjects',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/subjects`,{params});
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchSubjectsByClasses = createAsyncThunk(
  'subjects/getCommonSubjects',
  async (params: any, query: any) => {
    let data;

    try {
      const response = await axiosInstance.post(`subjects/shared-subject `, { ...params });
      data = await response.data;

  
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const subjectsSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubjects.fulfilled, (state, action) => {
      state.payload = action.payload.data.payload;
    });
  },
});

export const {} = subjectsSlice.actions;

export default subjectsSlice.reducer;
