import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { ReactComponent as Loader } from '../assets/img/icons/ixamee.svg';
import Cookies from 'js-cookie';
import axiosInstance from '../api/axios';
import { useDispatch } from 'react-redux';
import { setActiveEstablishment, setUser } from '../data/slices/AuthSlice';
import { notify } from './AntdNotifConfigProvider';
import { useNavigate } from 'react-router';
import { deleteAllCookies } from '../utils/deleteCookies';

interface State {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: User | null;
}
interface AuthContextType extends State {
  method: string;
  login: (
    email?: string,
    password?: string,
    access_Token?: string,
    refresh_Token?: string,
    stayConnected?: boolean
  ) => any;
  resetPassword: (email?: string, password?: string, confirmPassword?: string) => any;
  forgetPassword: (email: string) => any;
  verficationCode: (email?: string, code?: string) => any;
  logout: () => Promise<void>;
  register: ({
    firstName,
    lastName,
    email,
    government,
    establishment,
    phoneNumber,
    subject,
    password,
  }: Register) => Promise<void>;
}

interface Action extends State {
  type: string;
  payload?: any;
}
export interface Register {
  firstName?: string;
  lastName?: string;
  email?: string;
  government?: string;
  establishment?: string;
  phoneNumber?: string;
  subject?: string;
  password?: string;
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  governerment: string;
  subjects: any[];
  establishment: { _id: string; name: string }[];
}

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: User | null;
}
const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
export const isValidToken = (accessToken?: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};
export const setAccessSession = (accessToken?: string | null) => {
  if (accessToken && accessToken !== null) {
    Cookies.set('accessToken', accessToken);
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    Cookies.remove('accessToken');
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};
export const setRefreshSession = (refreshToken?: string | null) => {
  if (refreshToken && refreshToken !== null) {
    const decoded: any = jwtDecode(refreshToken);
    Cookies.set('refreshToken', refreshToken, {
      expires: new Date((decoded.exp - 5) * 1000),
    });
  } else {
    Cookies.remove('refreshToken');
    localStorage.clear();
    deleteAllCookies();
  }
};
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};
const AuthContext = createContext<AuthContextType>({
  ...initialAuthState,
  method: 'JWT',
  verficationCode: (email?: string, code?: string) => Promise.resolve(),
  login: () => Promise.resolve(),
  resetPassword: (email?: string, password?: string, confirmPassword?: string) => Promise.resolve(),
  forgetPassword: (email?: string) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const reduxDispatch = useDispatch();
  const login = async (
    email?: string,
    password?: string,
    access_Token?: string,
    refresh_Token?: string,
    stayConnected?: boolean
  ) => {
    if (stayConnected) {
      Cookies.set('stayConnected', JSON.stringify(stayConnected));
    }
    if (access_Token) {
      setAccessSession(access_Token);
      setRefreshSession(refresh_Token);
      const responseUser = await axiosInstance.get('/profile/me');
      const { user } = responseUser.data.data;
      dispatch({
        type: 'LOGIN',
        payload: {
          user: user,
        },
      });
      localStorage.setItem('activeEstablishmentId', user.establishment[0]?._id);
      reduxDispatch(setUser({ user }));
      return true;
    }
    let response;
    try {
      response = await axiosInstance.post('/auth/teacher/login', {
        email,
        password,
      });
    } catch (error) {
      return Promise.reject(error);
    }
    const { accessToken, refreshToken, filteredUser } = response.data.data;
    if (filteredUser?.verified === true || access_Token) {
      if (
        filteredUser.establishment.length === 0 ||
        filteredUser.subjects.length === 0 ||
        !filteredUser?.sessionId
      ) {
        navigate('/teacher-data', {
          state: {
            accessToken,
            refreshToken,
          },
        });
      } else {
        setAccessSession(accessToken);
        setRefreshSession(refreshToken);
        const responseUser = await axiosInstance.get('/profile/me');

        const { user } = responseUser.data.data;
        dispatch({
          type: 'LOGIN',
          payload: {
            user: user,
          },
        });
        localStorage.setItem('activeEstablishmentId', user.establishment[0]?._id);
        reduxDispatch(setUser({ user }));
      }
      return true;
    } else {
      navigate('/verification', {
        state: {
          prev: 'login',
          email: filteredUser?.email,
          accessToken,
          refreshToken,
        },
      });
    }
    return false;
  };

  const logout = async () => {
    await axiosInstance.delete('/auth/teacher/logout');
    setAccessSession(null);
    setRefreshSession(null);

    dispatch({ type: 'LOGOUT', isAuthenticated: false, user: null });
    window.location.href = '/login';
  };

  const register = async ({
    firstName,
    lastName,
    email,
    government,
    establishment,
    phoneNumber,
    subject,
    password,
  }: Register) => {
    const response = await axiosInstance.post('/auth/teacher/register', {
      firstName,
      lastName,
      email,
      government,
      establishment,
      phoneNumber,
      subject,
      password,
    });
    const { accessToken, user, refreshToken } = response.data.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
    navigate('/verification', {
      state: {
        prev: 'register',
        email: user?.email,
        accessToken,
        refreshToken,
      },
    });
  };
  const forgetPassword = async (email?: string) => {
    try {
      const response: any = await axiosInstance.post('/auth/teacher/forgot-password', { email });
      if (response?.status === 200) {
        notify(response?.data?.message, 'success');
        return true;
      }
    } catch (err) {
      // notify(err.response.data.message, 'error');
      return Promise.reject(err.response.data.message);
    }
  };
  const resetPassword = async (email?: string, password?: string, confirmPassword?: string) => {
    const response: any = await axiosInstance.patch('/auth/teacher/reset-password', {
      email,
      password,
      confirmPassword,
    });
    if (response?.status === 200) {
      notify('Le mot de passe a été changé avec succès', 'success');
      navigate('/login');
    }
  };
  const verficationCode = async (email?: string, code?: string) => {
    const response: any = await axiosInstance.post('/auth/teacher/verification-account', {
      email,
      code,
    });
  };
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = Cookies.get('accessToken');
        const refToken = Cookies.get('refreshToken');

        if (!accessToken) {
          return dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }

        if (!isValidToken(accessToken)) {
          const response: any = await axiosInstance.post('/auth/teacher/refresh-token', {
            refreshToken: refToken,
          });
          const { accessToken, filteredUser, refreshToken } = response.data.data;
          setAccessSession(accessToken);
          setRefreshSession(refreshToken);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: filteredUser,
            },
          });
        }
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        const response = await axiosInstance.get('/profile/me');

        const { user } = response.data.data;
        return setTimeout(() => {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          reduxDispatch(setUser({ user }));
        }, 1800);
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) {
    return (
      <div className="auth_loader">
        <Loader />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        resetPassword,
        logout,
        register,
        forgetPassword,
        verficationCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
