import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { avatarByGender } from '../../../utils/methods';
import useAuth from '../../../hooks/useAuth';
import { Avatar, Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useAppDispatch } from '../../../hooks/redux';
import { RootState } from '../../../data/store';
import ExitApp from '../../../assets/img/icons/log-out.png';
import Profile from '../../../assets/img/icons/user.png';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';

type Props = {
  name?: string;
};
const Index = ({ name }: Props) => {
  const { logout } = useAuth();
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();

  interface User {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    government: string;
    establishment: string;
    subject: string;
    password: string;
    role: string;
    photo: string;
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.TouchEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef<boolean>(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to="/me">
          <div className="drop-down-item">
            <img alt="My profile" src={Profile} />
            Mon profil
          </div>
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <button onClick={handleLogout}>
          <div className="drop-down-item">
            <img alt="logout" src={ExitApp} />
            Déconnecter
          </div>
        </button>
      ),
    },
  ];
  return (
    <Space direction="vertical" className="layout-header">
      <Space wrap>
        <Dropdown trigger={['click']} menu={{ items }} placement="bottom">
          <Button>
            <div className="user">
              {user?.photo ? (
                <div
                  style={{
                    width: '42px',
                    height: '42px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={user?.photo}
                    alt="Profile"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </div>
              ) : (
                <Avatar
                  className="user-pic"
                  alt={user?.firstName}
                  src={avatarByGender({ avatar: null, gender: 'male' })}
                  id="img-preview"
                />
              )}

              <div className="user_details">
                <h2 className="user_details-name text-color-2" style={{ textTransform: 'none' }}>
                  {user?.firstName} {user?.lastName}
                </h2>
                <h2 className="user_details-subject text-color-12">{user?.email.toLowerCase()}</h2>
              </div>
              <div className="icon-holder">
                <KeyboardArrowDown />
              </div>
            </div>
          </Button>
        </Dropdown>
      </Space>
    </Space>
  );
};

Index.propTypes = {};

export default Index;
