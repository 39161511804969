import { UniqueIdentifier } from '@dnd-kit/core';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../utils/axios';
//@ts-ignore
import { saveAs } from 'file-saver';
import { message } from 'antd';
type RangeAndPhoto = {
  rang: string;
  photo: string;
};
type Student = {
  id: number;
  name: string;
  email: string;
  rangAndPhoto: RangeAndPhoto;
};
export type StudentInviteData = {
  name: string;
  email: string;
  classId: string | undefined;
};
type StudentsState = {
  students: Student[];
  classExams: any;
  searchStudents: [];
  status: 'loading' | 'idle' | 'failed';
  shouldFetchStudentsAgain: boolean;
  classData: any;
  error: string | null;
  classActive: string;
  importCsv: 'fulfilled' | 'rejected' | 'pending';
  pagination: {
    current: number;
    perPage: number;
    total: number;
    totalDocs: number;
    pageSize: number;
  };
  searchValue: string;
  studentActive: string;
  examFilter: { _id: string; name: string } | null;
  studentData: any;
  imageUrl: any;
  invitedStudents: string[];
  invitationCoord: StudentInviteData;
  showInvitationPopup: boolean;
};
type PaginationStudent = {
  page: number;
  perPage: number;
  search?: string;
  classId?: string | undefined;
  examId?: string | undefined;
  pageSize?: number;
  sort?: string;
  rate?: any;
  correctionProgress?: number;
};
export type StudentAddData = {
  firstName: string;
  lastName: string;
  email: string;
  range: string;
  classId: string | undefined;
};
type ChangeRangStudent = {
  classId?: string | undefined;
  dragerId: UniqueIdentifier | undefined;
  droperId: UniqueIdentifier | undefined;
};
const initialState: StudentsState = {
  students: [],
  status: 'idle',
  classExams: [],
  classData: {},
  classActive: 'class',
  importCsv: 'fulfilled',
  shouldFetchStudentsAgain: false,
  error: null,
  pagination: {
    current: 1,
    perPage: 8,
    totalDocs: 0,
    total: 0,
    pageSize: 8,
  },
  searchValue: '',
  studentActive: '',
  examFilter: null,
  searchStudents: [],
  studentData: {},
  imageUrl: [],
  invitedStudents: [],
  invitationCoord: { name: '', email: '', classId: '' },
  showInvitationPopup: false,
};
export const exportStudent = createAsyncThunk(
  'students/exportCsv',
  async ({ id, name }: any, { getState }) => {
    try {
      const response = await axiosInstance.get(`/classes/exportStudents/${id}`);

      const blob = new Blob([response.data], { type: 'text/csv' });

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return response.data.data;
    } catch (error) {
      throw error.response.data.message || 'Something went wrong.';
    }
  }
);
export const searchStudents = createAsyncThunk(
  'students/get',
  async ({ ...params }: any, { getState }) => {
    try {
      const response = await axiosInstance.get(`students/search-students`, { params });
      return response.data.data;
    } catch (error) {
      throw error.response.data.message || 'Something went wrong.';
    }
  }
);

export const importStudents = createAsyncThunk(
  'students/importStudents',
  async ({ classId, formData }: any, { getState }) => {
    try {
      const res = await axiosInstance.post(`classes/importStudents/${classId}`, formData);
      console.log(res?.data);
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error.message);
    }
  }
);
export const downloadPdf = (classId: string) => {
  return axiosInstance.get(`students/student-pdf/${classId}`, {
    responseType: 'arraybuffer',
  });
};
export async function printTickets(classId: any, name: any) {
  const { data } = await downloadPdf(classId);

  const blob = new Blob([data], { type: 'application/pdf' });
  saveAs(blob, `${name}.pdf`);
}
export const fetchStudents = createAsyncThunk(
  'students/fetchStudents',
  async (props: PaginationStudent, { getState }) => {
    const { classId, pageSize, examId, ...params } = props;

    try {
      const response = await axiosInstance.get(
        `/students/${classId}${examId ? `?examId=${examId}` : ``}`,
        {
          params,
        }
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data.message || 'Something went wrong.';
    }
  }
);
export const changeStudentRange = createAsyncThunk(
  'students/changeRange',
  async (props: ChangeRangStudent, { getState }) => {
    const { classId, ...params } = props;

    try {
      const response = await axiosInstance.put(`/classes/drag/${classId}`, { ...params });
      message.success(response.data.message)
      return response.data.data;
    } catch (error) {
      message.error(error.message);
    }
  }
);
export const sendCorrections = createAsyncThunk(
  'send/corrections',
  async (props: any, thunkApi) => {
    try {
      const res = await axiosInstance.post(`/students/send-correctionExam`, {
        ...props,
      });
      message.success('résultats envoyés avec succès');
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const addStudent = createAsyncThunk('student/add', async (props: any, thunkApi) => {
  const { formData, classId } = props;
  try {
    const res = await axiosInstance.post(`/students/create-student/${classId}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    message.success(res.data.message)

    return res.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.message);
  }
});

export const inviteStudent = createAsyncThunk(
  'student/invite',
  async (props: StudentInviteData, { getState }) => {
    try {
      const { name, email, classId } = props;
      const data = { name: name, email: email };

      const res = await axiosInstance.patch(`/students/invite-student/${classId}`, data);
      return res.data;
    } catch (error) {
      return Promise.reject(error.response.data.message || 'Something went wrong.');
    }
  }
);
export const deleteStudent = createAsyncThunk(
  'student/delete',
  async (props: any, { getState }) => {
    try {
      const { students, classId } = props;  
      const res = await axiosInstance.post(`students/${students[0]}/class/${classId}`);
      message.success(res.data.message)
      return res;
    } catch (error) {
      message.error(error.message);
    }
  }
);
export const deleteMultipleStudent = createAsyncThunk(
  'student/deleteMultiple',
  async (props: any, { getState }) => {
    try {
      const { students, classId } = props;

      const res = await axiosInstance.delete(`students/deleteMany/class/${classId}`, {
        data: { students },
      });
      message.success(res.data.message)
      return res;
    } catch (error) {
      message.error(error.message);
    }
  }
);

export const currentStudent = createAsyncThunk('student/profile', async (props: any, thunkApi) => {
  try {
    const { studentId, classId } = props;
    const res = await axiosInstance.get(`/students/${studentId}/${classId}`);
    return res.data.data;
  } catch (error) {
    thunkApi.rejectWithValue('there was eror');
  }
});
export const editStudent = createAsyncThunk('students/edit', async (props: any, thunkApi) => {
  const { formData, studentId } = props;

  try {
    const res = await axiosInstance.patch(`/students/${studentId}`, formData);
    message.success(res.data.message)
    return res.data;
  } catch (error) {
    thunkApi.rejectWithValue('there was eror');
  }
});
const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    removesStudents(state) {
      state.students = [];
    },
    removeCurrentStudent(state) {
      state.studentData = [];
      state.classData = {};
    },
    setStudentSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setPage(state, action) {
      state.pagination.current = action.payload;
    },
    setStudentActive(state, action) {
      state.studentActive = action.payload;
    },
    setExamFilter(state, action) {
      state.examFilter = action.payload;
    },
    setShowInvitationCoord(state, action) {
      state.invitationCoord = action.payload;
    },
    setShowInvitationPopup(state, action) {
      state.showInvitationPopup = action.payload;
    },
    updateStudentRange(
      state,
      {
        payload,
      }: {
        payload: {
          students: [];
        };
      }
    ) {
      const students = payload.students;
      const updatedRangeStudents = students.map((student: any, i) => ({
        ...student,
        rangAndPhoto: { ...student.rangAndPhoto, rang: i + 1 },
      }));
      state.students = updatedRangeStudents;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudents.pending, (state) => {
        state.status = 'loading';
        state.students = [];
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.status = 'idle';

        state.classExams =
          action?.payload?.payload[action?.payload?.payload.length - 2]?.foundedExams;
        state.students = action?.payload?.payload
          ?.slice(1, action?.payload?.payload.length - 2)
          ?.map((eleve: any) => ({
            id: eleve._id,
            name: eleve.firstName + ' ' + eleve.lastName,
            firstName: eleve.firstName,
            lastName: eleve.lastName,
            email: eleve.email,
            rangAndPhoto: { rang: eleve.range, photo: eleve.photo },
            invited: eleve.isInvited,
            correctionProgress: eleve.correctionProgress,
            isAbsent: eleve.isAbsent,
            unranked: eleve.unranked,
          }));
        state.classActive = action?.payload?.payload.slice(0, 1).at(0)?.class_name;
        state.classData = action?.payload?.payload[0];
        state.pagination.totalDocs = action.payload.meta.totalDocs;
        state.pagination.current = action.payload.meta.currentPage;
        state.pagination.total = action.payload.meta.totalPages;
        state.invitedStudents =
          action.payload.payload[action.payload.payload.length - 1].invitedStudent;
        state.shouldFetchStudentsAgain = false;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to fetch students';
      })
      .addCase(addStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to add students';
      })
      .addCase(addStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        state.pagination.total = action.payload.totalItems;
        state.shouldFetchStudentsAgain = true;
      })
      .addCase(inviteStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(inviteStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        state.shouldFetchStudentsAgain = true;
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to delete students';
      })
      .addCase(deleteStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        state.shouldFetchStudentsAgain = true;
      })
      .addCase(currentStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to get students';
      })
      .addCase(currentStudent.pending, (state) => {
        state.status = 'loading';
        state.studentData = {};
        state.imageUrl = '';
      })
      .addCase(currentStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.studentData = action.payload.payload;
        state.imageUrl = action.payload.payload.photo;
        state.error = null;
        state.shouldFetchStudentsAgain = false;
      })
      .addCase(editStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to edit students';
      })
      .addCase(editStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        state.shouldFetchStudentsAgain = true;
      })
      .addCase(deleteMultipleStudent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to delete students';
      })
      .addCase(deleteMultipleStudent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteMultipleStudent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        state.shouldFetchStudentsAgain = true;
      })
      .addCase(changeStudentRange.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(changeStudentRange.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(changeStudentRange.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to change  student rang';
      })
      .addCase(searchStudents.pending, (state, action) => {})
      .addCase(searchStudents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(searchStudents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? 'Failed to change  student rang';
      })
      .addCase(importStudents.fulfilled, (state) => {
        state.importCsv = 'fulfilled';
      })
      .addCase(importStudents.pending, (state) => {
        state.importCsv = 'pending';
      })
      .addCase(importStudents.rejected, (state) => {
        state.importCsv = 'rejected';
      });
  },
});

export const {
  setStudentSearchValue,
  setPage,
  setStudentActive,
  setExamFilter,
  setShowInvitationCoord,
  setShowInvitationPopup,
  updateStudentRange,
  removeCurrentStudent,
  removesStudents,
} = studentsSlice.actions;

export default studentsSlice.reducer;
