import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface SearchState {
  search: [];
  status: string | null;
  error: string | null;
}
// Define the initial state using that type
const initialState: SearchState = {
  search: [],
  status: 'idle',
  error: null,
};

// Define the asynchronous thunk for fetching users

export const searchSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Export the actions and reducer
export const {} = searchSlice.actions;
export default searchSlice.reducer;
