import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { RootState } from '../store';

interface Bulletin {
  exams: any[];
  examsPdf: any[];
  error: string;
  currentClassId: '';
  loading: boolean;
}

const initialState: Bulletin = {
  exams: [],
  examsPdf: [],
  error: '',
  currentClassId: '',
  loading: false,
};

export const GetCurrentClassId = createAsyncThunk('currentClassId', async (params: any) => {
  const { currentClassId } = params;
  try {
    return currentClassId;
  } catch (error) {
    throw error;
  }
});

export const fetchBulletin = createAsyncThunk('exam/Bulletin', async (props: any, { getState }) => {
  const { classId, sessionId, subjectId, ...params } = props;
  try {
    const response = await axiosInstance.get(`/mark-sheets/${sessionId}/${classId}/${subjectId}`, {
      params: { ...params, page: 1, perPage: 1000 },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchBulletinPdf = createAsyncThunk(
  'exam/BulletinPdf',
  async (props: any, { getState }) => {
    const { classId, sessionId, subjectId, ...params } = props;
    try {
      const response = await axiosInstance.get(
        `/mark-sheets/download-pdf/class/${classId}/session/${sessionId}/subject/${subjectId}`
      );
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const BulletinSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentClassId: (state, action) => {
      state.currentClassId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBulletin.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(fetchBulletin.fulfilled, (state, { payload }) => {
        state.exams = payload;
        state.loading = false;
      })
      .addCase(fetchBulletin.rejected, (state, action) => {
        state.exams = [];
        state.error = action.error.message || 'Something went wrong';
        state.loading = false;
      })

      .addCase(fetchBulletinPdf.fulfilled, (state, { payload }) => {
        state.examsPdf = payload;
      });
  },
});

export const { setCurrentClassId } = BulletinSlice.actions;
export default BulletinSlice.reducer;
