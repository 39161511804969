import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { RootState } from '../store';
export interface Level {
  _id: number;
  name: string;
  students: string;
  updatedAt: Date;
}
export interface EditClassData {
  name: string;
  classId: string;
}
export interface StudentList {
  page: number;
  perPage?: number;
  classId: string | undefined;
}
type PaginationClasses = {
  page?: number;
  pageSize?: number;
  search?: string;
  perPage?: number;
  async?: boolean;
  sort?: string;
};
export interface ClassState {
  classes: Level[];
  archiveClasses: Level[];
  classesList: any[];
  isLoading: boolean;
  error: string | null;
  establishmentId: string;
  shouldFetchClasses: boolean;
  shouldFetchStudents: boolean;
  students: [];
  currentClass: Level;
  searchValue: string;
  paginationClass: {
    current: number;
    pageSize: number;
    total: number;
    totalPages: number;

    perPage: number;
  };
  paginationClassArchive: {
    current: number;
    pageSize: number;
    total: number;
    totalPages: number;
    perPage: number;
  };
  classActive: string;
  meta: any;
}

const initialState: ClassState = {
  classes: [],
  archiveClasses: [],
  classesList: [],
  meta: {},
  isLoading: false,
  error: null,
  establishmentId: '',
  shouldFetchClasses: false,
  shouldFetchStudents: false,
  currentClass: {} as Level,
  students: [],
  searchValue: '',
  paginationClass: {
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },

  paginationClassArchive: {
    current: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  classActive: 'Classes',
};

export const fetchClass = createAsyncThunk(
  'class/fetchClass',
  async (props: PaginationClasses, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');

    const { pageSize, async, ...params } = props;

    try {
      const response = await axiosInstance.get(`/classes/${establishmentId}`, {
        params,
      });

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchClassPagination = createAsyncThunk(
  'class/fetchClass',
  async (props: PaginationClasses, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');

    const { pageSize, ...params } = props;

    try {
      const response = await axiosInstance.get(`/classes/${establishmentId}`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const copyClass = createAsyncThunk('classes/copyClass', async (params: any, query: any) => {
  let data;
  const { id, ...other } = params;
  try {
    const response = await axiosInstance.post(`classes/copier-class/${id}`, { ...other });
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const fetchClassList = createAsyncThunk(
  'class/fetchClassList',
  async (props: any, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');
    const { body } = props;
    let response;
    try {
      if (body) response = await axiosInstance.post(`classes/establishments`, body);
      else response = await axiosInstance.get(`/classes/${establishmentId}`);

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchClassArchive = createAsyncThunk(
  'class/fetchClassArchive',
  async (props: PaginationClasses, { getState }) => {
    const establishmentId = localStorage.getItem('activeEstablishmentId');

    const { pageSize, ...params } = props;
    try {
      const response = await axiosInstance.get(`/classes/${establishmentId}?deleted=true`, {
        params,
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const addClass = createAsyncThunk('class/create', async (props: any, { getState }) => {
  const establishmentId = localStorage.getItem('activeEstablishmentId');

  try {
    const data = props;
    const res = await axiosInstance.post(`/classes/${establishmentId}`, {
      name: props?.name,
      subjects: props.subjects,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
});
export const editClass = createAsyncThunk('class/edit', async (props: any, thunkApi) => {
  try {
    const { classId, name, subjects, ...values } = props;
    const res = await axiosInstance.patch(`/classes/${classId}`, {
      name,
      subjects: subjects,
    });

    return res.data;
  } catch (error) {
    thunkApi.rejectWithValue('there was eror');
  }
});
export const deleteClass = createAsyncThunk('class/deleteClass', async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/classes/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
});
export const archiveClass = createAsyncThunk('class/archiveClass', async (id: string) => {
  try {
    const response = await axiosInstance.patch(`/classes/archive/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});
export const unarchiveClass = createAsyncThunk('class/unarchiveClass', async (id: string) => {
  try {
    const response = await axiosInstance.put(`/classes/unarchive/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
});

export const fetchStudents = createAsyncThunk('class/fetchStudents', async (props: any) => {
  try {
    const { classId, ...params } = props;
    const response = await axiosInstance.get(`/classes/${classId}`, { params });
    localStorage.setItem(
      'students',
      JSON.stringify(response.data.data.students.findAllQuery[0].students)
    );

    return response.data.data.students.findAllQuery[0].students;
  } catch (error) {
    throw error;
  }
});
const classSlice = createSlice({
  name: 'class',
  initialState,
  reducers: {
    setClassSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setPageClass(state, action) {
      state.paginationClass.current = action.payload;
    },
    setPageSize(state, action) {
      state.paginationClass.pageSize = action.payload;
      state.paginationClass.perPage = action.payload;
    },
    setPageClassArchive(state, action) {
      state.paginationClassArchive.current = action.payload;
    },
    setClassActive(state, action) {
      state.classActive = action.payload;
    },
    pushClasses(state, action) {
      state.classes.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClass.fulfilled, (state, action) => {
        if (action.meta.arg.async) {
        } else {
          state.classes = action.payload.payload;

          state.meta = action.payload.meta;
          state.isLoading = false;
          state.error = null;
          state.paginationClass.current = action.payload.meta.page;
          state.paginationClass.total = action.payload.meta.totalDocs;
          state.shouldFetchClasses = false;
        }
      })
      .addCase(fetchClass.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(fetchClassList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClassList.fulfilled, (state, action) => {
        state.classesList = action.payload.payload;
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = false;
      })
      .addCase(fetchClassList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(fetchClassArchive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClassArchive.fulfilled, (state, action) => {
        state.archiveClasses = action.payload.payload;
        state.isLoading = false;
        state.error = null;

        state.paginationClassArchive.current = action.payload.meta.page;
        state.paginationClassArchive.total = action.payload.meta.totalDocs;
        state.paginationClassArchive.totalPages = action.payload.meta.totalPages;

        state.shouldFetchClasses = false;
      })
      .addCase(fetchClassArchive.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(fetchStudents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.students = action.payload;
        state.isLoading = false;
        state.error = null;
        state.shouldFetchStudents = false;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch class';
      })
      .addCase(deleteClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = true;
      })
      .addCase(archiveClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = true;
      })
      .addCase(unarchiveClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unarchiveClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = true;
      })
      .addCase(addClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = true;
      })
      .addCase(editClass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editClass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.shouldFetchClasses = true;
      });
  },
});

export const {
  setClassSearchValue,
  setPageClass,
  setPageSize,
  setPageClassArchive,
  setClassActive,
} = classSlice.actions;
export default classSlice.reducer;
