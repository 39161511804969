import React from 'react';
import Sidebar from '../Sidebar';

type Props = {
  children?: any;
};

const DashboardLayout = ({ children }: Props) => {
  return <Sidebar>{children}</Sidebar>;
};

export default DashboardLayout;
