import { ConfigProvider, notification } from 'antd';
import React, { ReactNode } from 'react';

notification.config({
  duration: 1.2,
  maxCount: 1,
});

function AntdNotifConfigProvider({ children }: { children: ReactNode }) {
  return <ConfigProvider>{children}</ConfigProvider>;
}

export const notify = (message: string, type: 'success' | 'error' | 'info' | 'warning') => {
  notification[type]({
    message: message,
  });
};

export default AntdNotifConfigProvider;
