import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message, UploadFile } from 'antd';
import axios from 'axios';
import axiosInstance from '../../utils/axios';
import Cookies from 'js-cookie';

export interface Establishment {
  _id: string;
  name: string;
}
export interface Subject {
  _id: string;
  name: string;
}
interface User {
  establishment: Establishment[];
  _id?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber: string;
  subjects: Subject[];
  government: string;
}

interface AuthState {
  activeEstablishment: string;
  activeEstablishmentName: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  user: User;
  data: User | null;
  message: string;
  shouldFetchaAgain: boolean;
  fileList: UploadFile[];
}

export const initialState: AuthState = {
  activeEstablishment: '',
  activeEstablishmentName: '',
  status: 'idle',
  error: null,
  user: {
    establishment: [],

    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    subjects: [],
    government: '',
  },
  data: null,
  message: '',
  shouldFetchaAgain: false,
  fileList: [],
};

//current user

export const currentUser = createAsyncThunk<
  unknown,
  void,
  {
    rejectValue: string;
  }
>('user/currentUser', async (_, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get('/profile/me');
    data = response.data.data;

    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    thunkAPI.rejectWithValue(err.message);
  }
});
export const editProfile = createAsyncThunk('TeacherProfile/edit', async (props: any, thunkApi) => {
  const { formData, headers } = props;
  try {
    let res;
    if (headers) {
      res = await axios.patch(`${process.env.REACT_APP_API_URL}/profile`, formData, {
        headers: { Authorization: `Bearer ${headers}` },
      });
      Cookies.set('stayConnected', JSON.stringify(true));
    } else {
      res = await axiosInstance.patch('/profile', formData);
    }

    return res.data;
  } catch (error) {
    return Promise.reject(
      error?.response?.data
        ? error?.response?.data?.message
        : error.message
        ? error.message
        : error?.message
    );
  }
});
export const editPassword = createAsyncThunk(
  'TeacherProfile/edit-password',
  async (props: any, thunkApi) => {
    try {
      const res = await axiosInstance.patch('/profile/updater-password', props);

      return res.data;
    } catch (error) {
      thunkApi.rejectWithValue('there was eror');
    }
  }
);
export const authSlice: any = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setActiveEstablishment(state, action) {
      state.activeEstablishment = action.payload;
    },
    setActiveEstablishmentName(state, action) {
      state.activeEstablishmentName = action.payload;
    },
    setFileList(state, action) {
      state.fileList = action.payload;
    },
    setUser(state, action) {
      state.status = 'succeeded';
      state.user = action?.payload?.user;
      state.shouldFetchaAgain = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(currentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(currentUser.fulfilled, (state, action: any) => {
        state.status = 'succeeded';
        state.user = action?.payload?.user;
        state.shouldFetchaAgain = false;
      })
      .addCase(currentUser.rejected, (state, action: any) => {
        state.status = 'failed';
        state.message = action?.payload?.message;
      })
      .addCase(editProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editProfile.fulfilled, (state, action: any) => {
        state.status = 'succeeded';
        state.shouldFetchaAgain = true;
      })
      .addCase(editProfile.rejected, (state, action: any) => {
        state.status = 'failed';
        state.message = action?.payload?.message;
      })
      .addCase(editPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editPassword.fulfilled, (state, action: any) => {
        state.status = 'succeeded';
        message.success('mot de passe changé avec succès');
        state.shouldFetchaAgain = true;
      })
      .addCase(editPassword.rejected, (state, action: any) => {
        state.status = 'failed';
        state.message = action?.payload?.message;
      });
  },
});
export const { setUser, setActiveEstablishment, setActiveEstablishmentName, setFileList } =
  authSlice.actions;
export default authSlice.reducer;
