import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api/axios';
import axiosInstance from '../../utils/axios';
import { RootState } from '../store';

export interface EstablishmentState {
  _id: string;
  name: string;
}
interface State {
  payload: EstablishmentState[];
  activeEstablishment: EstablishmentState;
}
const initialState: State = {
  payload: [],
  activeEstablishment: { _id: '', name: '' },
};
export const fetchEstablishements = createAsyncThunk(
  'teacherDashboard/getAllExames',
  async (params: any, query: any) => {
    let data;
    try {
      const response = await axiosInstance.get(`/establishments`,{params});
      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const establishmentsSlice = createSlice({
  name: 'establishment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEstablishements.fulfilled, (state, action) => {
      state.payload = action.payload.data.docs;
    });
  },
});

export const {} = establishmentsSlice.actions;

export default establishmentsSlice.reducer;
