import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './data/store';
import './App.scss';
import AntdNotifConfigProvider from './contexts/AntdNotifConfigProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <Provider store={store}>
      <AntdNotifConfigProvider>
        <App />
      </AntdNotifConfigProvider>
    </Provider>
  </>
);
