import React, { Fragment } from 'react';
import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import LoadingScreen from './components/ui/Loading';
import { pageLoader } from './utils/lazyImport';
import { IRouteItem } from './types/routes';
import AuthGuard from './layouts/guards/AuthGuard';
import DashboardLayout from './layouts/DashboardLayout';
import GuestGuard from './layouts/guards/GuestGuard';
export const renderRoutes = (routes: IRouteItem[] = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route, index) => {
          const Component = route.component;
          const Guard = route.guard;
          const Layout = route.layout || Fragment;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Guard>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

const routes: IRouteItem[] = [
  {
    exact: true,
    path: '/',
    component: lazy(pageLoader(() => import('./pages/dashboard'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/me',
    component: lazy(pageLoader(() => import('./features/ProfileTeacher/pages/ProfileTeacher'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/dashboard',
    component: lazy(pageLoader(() => import('./pages/dashboard'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: 'exams/construction/:id',
    component: lazy(pageLoader(() => import('./features/Exams/pages/constructionExamen'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/classes',
    component: lazy(pageLoader(() => import('./features/Classes/pages/Classes'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/classes/:classId',
    component: lazy(pageLoader(() => import('./features/Classes/pages/StudentsList'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/classes/:classId/students/:studentId',
    component: lazy(pageLoader(() => import('./features/ProfileTeacher/pages/ProfileStudent'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/classes/:classId/students/:studentId/correction/:examId',
    component: lazy(pageLoader(() => import('./features/Correction/Correction'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/exams',
    component: lazy(pageLoader(() => import('./features/Classes/pages/Exams'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/bulletin',
    component: lazy(pageLoader(() => import('./features/Bulletin/pages/Bulletin'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/bulletin/:className/:studentId/:classId/:subjectId',
    component: lazy(
      pageLoader(() => import('./features/Bulletin/components/StudentBulletin/StudentBulletin'))
    ),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/calendar',
    component: lazy(pageLoader(() => import('./pages/calendar'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/archive',
    component: lazy(pageLoader(() => import('./features/Archive/Archive'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/settings/establishements',
    component: lazy(pageLoader(() => import('./pages/settings/establishements/Establishements'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/settings/subjects',
    component: lazy(pageLoader(() => import('./pages/settings/subjects/Subjects'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/settings/teachers',
    component: lazy(pageLoader(() => import('./pages/settings/teachers/Teachers'))),
    guard: AuthGuard,
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: '/login',
    component: lazy(pageLoader(() => import('./features/Auth/pages/Login'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/signup',
    component: lazy(pageLoader(() => import('./features/Auth/pages/Signup'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/forget-password',
    component: lazy(pageLoader(() => import('./features/Auth/pages/ForgetPassword'))),
    guard: GuestGuard,
    layout: Fragment,
  },

  {
    exact: true,
    path: '/account-verification',
    component: lazy(pageLoader(() => import('./features/Auth/pages/AccountVerification'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/reset-password',
    component: lazy(pageLoader(() => import('./features/Auth/pages/ResetPassword'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/verification',
    component: lazy(pageLoader(() => import('./features/Auth/pages/Verification'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/teacher-data',
    component: lazy(pageLoader(() => import('./features/Auth/pages/TeacherData'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '/network',
    component: lazy(pageLoader(() => import('./features/404/NetworError'))),
    guard: GuestGuard,
    layout: Fragment,
  },
  {
    exact: true,
    path: '*',
    component: lazy(pageLoader(() => import('./features/404/NotFound'))),
    guard: AuthGuard,
    layout: Fragment,
  },
];

export default routes;
