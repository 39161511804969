import React from 'react';
import routes, { renderRoutes } from './routes';
import { AuthProvider, setAccessSession, setRefreshSession } from './contexts/JWTAuthContext';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

function App() {
  const beforeUnloadHandler = (e: any) => {
    e.preventDefault();
    const stayConnected = Cookies.get('stayConnected');

    const url: any = new URL(e.target.referrer);
    const pathComponents = url.pathname.split('/');
    const lastParam = pathComponents[pathComponents.length - 1];
    if (lastParam === 'network') {
      console.log(lastParam);
      return false;
    }
    if (!stayConnected) {
      setRefreshSession(null);
      setAccessSession(null);
      return false;
    }
    return true;
  };
  window.addEventListener('unload', function (e) {
    return beforeUnloadHandler(e);
  });


  return (
    <div>
      <BrowserRouter>
        <AuthProvider>{renderRoutes(routes)}</AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
