import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isValidToken } from '../contexts/JWTAuthContext';

const BASE_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    config.headers['Authorization'] = `Bearer ${Cookies.get('accessToken')}`;
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.log(error);
    const previousRequest: any = error?.config;
    if (
      error?.response?.status === 400 &&
      error?.response?.data === 'jwt expired' &&
      !previousRequest?.sent
    ) {
      previousRequest.sent = true;
      if (!isValidToken(error?.config?.params?.refresh_token)) {
        Cookies.remove('refreshToken');
        Cookies.remove('accessToken');
      }
      const response = await axiosInstance.post('auth/token', null, {
        params: {
          grant_type: 'refresh_token',
          refresh_token: Cookies.get('refreshToken'),
        },
      });
      const newAccessToken = response.data.access_token;
      Cookies.set('accessToken', newAccessToken);
      Cookies.set('refreshToken', response.data.refresh_token);
      previousRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      return axiosInstance(previousRequest);
    } else if (error?.response?.status === 401 && error.response.statusText === 'Unauthorized') {
      const response = await axiosInstance.post('auth/token', null, {
        params: {
          grant_type: 'refresh_token',
          refresh_token: Cookies.get('refreshToken'),
        },
      });
      Cookies.set('accessToken', response.data.access_token);
      return axiosInstance(previousRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
